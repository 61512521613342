<template>
  <v-container>
    <!-- GALLERY VIEW BELOW -->
    <v-card class="theDragGallery">
      <v-chip label v-if="orderIsNew" color="orange">
        Neue Reihenfolge ist noch nicht gespeichert!
      </v-chip>
      <v-row>
        <draggable
          v-model="imagesToSort"
          draggable=".dragImage"
          class="row pa-5"
          @sort="updateSortingOrder"
        >
          <v-col
            v-for="i in imagesToSort"
            :key="i.key"
            class="dragImage"
            cols="4"
          >
            <v-hover v-slot="{ hover }">
              <v-img
                :src="i.thumbUrl"
                :alt="i.description"
                max-height="150px"
                contain
              >
                <span style="position:absolute; bottom:2px;">
                  {{ i.description }}
                </span>

                <v-row
                  style="position:absolute; top:2px; right:2px;"
                  v-if="hover"
                >
                  <v-col>
                    <v-btn
                      @click.stop="deleteKey = i.key"
                      fab
                      small
                      color="red"
                    >
                      <v-icon> mdi-delete-forever</v-icon>
                    </v-btn>

                    <v-btn
                      @click="$emit('uploadNewImg', slug, i.key)"
                      fab
                      small
                    >
                      <v-icon>mdi-file-edit</v-icon>
                    </v-btn>
                  </v-col>
                </v-row>
              </v-img>
            </v-hover>
          </v-col>
        </draggable>
      </v-row>

      <v-card-actions>
        <v-btn @click="saveNewPictureOrder(slug)" :disabled="!orderIsNew">
          Neure Reihenfolge speichern
        </v-btn>
        <v-btn @click="$emit('uploadNewImg', slug)">
          <v-icon>mdi-plus</v-icon>
          Neues bild
        </v-btn>
      </v-card-actions>
    </v-card>
    <!-- sure to delete dialog -->
    <v-dialog v-model="toDelete" max-width="290">
      <v-card>
        <v-card-title class="text-h5">
          Sicher dass dieses Bild gelöscht werden soll?
          <v-img
            v-if="toDelete"
            :src="toDelete.thumbUrl"
            height="200"
            contain
          />
        </v-card-title>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn color="red" text @click="deleteImage">
            Ja, löschen!
          </v-btn>

          <v-btn text @click="deleteKey = null">
            Nein, behalten
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import draggable from "vuedraggable";
import { mapGetters } from "vuex";

export default {
  name: "ReorderImages",
  components: {
    draggable
  },
  data() {
    return {
      orderIsNew: false,
      deleteKey: null
    };
  },
  props: {
    slug: { type: String, required: true }
  },
  computed: {
    ...mapGetters(["getImages", "getSingleImage"]),
    imagesToShow() {
      return this.getImages(this.slug);
    },

    imagesToSort: {
      get() {
        return this.imagesToShow;
      },
      set(value) {
        console.log(value);
        //this.$store.commit('setOrderOfImages', value)
        this.$store.commit("setOrderOfImages", value);
      }
    },
    toDelete() {
      if (this.deleteKey) {
        return this.getSingleImage(this.deleteKey);
      } else {
        return null;
      }
    }
  },
  methods: {
    async deleteImage() {
      await this.$store.dispatch("deletePicture", { picKey: this.deleteKey });
      this.deleteKey = null;
    },

    updateSortingOrder(newArray) {
      this.orderIsNew = true;
      this.$store.commit("setOrderOfImages", newArray);
      console.log("updated store...");
    },
    async saveNewPictureOrder(slug) {
      await this.$store.dispatch("saveNewPictureOrder", { slug: slug });
      this.orderIsNew = false;
    }
  }
};
</script>
