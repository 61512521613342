<template>
    <div>
        Hinweis: wenn das "titel" feld frei bleibt wird die Ankündigung nicht angezeigt!
        <textblock-form textSlug="notification" />

        <v-card>
            <v-card-title>Ankündigungs Bild </v-card-title>
            <v-img
                v-if="titleImg"
                :src="titleImg.url"
                alt="Märkte bild"
                max-height="400"
                contain
            />
            <p v-else>Noch kein Ankündigungs Bild vorhanden</p>

            <v-card-actions>
                <v-btn v-if="titleImg" @click="uploadNewImg('notification', titleImg.key)">
                <v-icon>mdi-file-edit</v-icon>
                Bild ändern
                </v-btn>
                <v-btn v-else @click="uploadNewImg('notification')">
                <v-icon>mdi-file-edit</v-icon>
                Neues bild
                </v-btn>
            </v-card-actions>
        </v-card>

        
        <!-- image upload dialog -->
        <v-dialog v-model="uploadDialog" persistent max-width="600px">
            <admin-image-upload
                :slug="uploadSlug"
                :imageID="imageToEdit"
                @done="uploadDialog = false"
            />
        </v-dialog>
    </div>
</template>

<script>
import { mapGetters } from "vuex";
import TextblockForm from "@/adminComponents/AdminTextField.vue";
import AdminImageUpload from "../../adminComponents/AdminImageUpload.vue";

export default {
    name: 'adminNotifications',
    data() {
        return {
            uploadSlug: null,
            imageToEdit: null,
            uploadDialog: false
        }
    },
    components: {
        TextblockForm,
        AdminImageUpload,
        // AdminSingleImage
    },
    computed: {  
        ...mapGetters(["getImageBySlug"]),
        titleImg() {
        return this.getImageBySlug("notification");
        },
    },
  methods: {
        uploadNewImg(slug, imageKey = null) {
        this.uploadSlug = slug;
        if (imageKey) {
            this.imageToEdit = imageKey;
        }
        this.uploadDialog = true;
        }
  },
    
}
</script>