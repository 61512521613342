<template>
  <v-container>
    <h1>Bilder bearbeiten</h1>

    <v-card>
      <v-card-title>Slideshow </v-card-title>
      <admin-reorder-images
        slug="slideshow"
        @saveNewPictureOrder="saveNewPictureOrder"
        @uploadNewImg="uploadNewImg"
      />
    </v-card>

    <v-card>
      <v-card-title>Produktgalerie </v-card-title>
      <admin-reorder-images
        slug="gallery"
        @saveNewPictureOrder="saveNewPictureOrder"
        @uploadNewImg="uploadNewImg"
      />
    </v-card>

    <v-card>
      <v-card-title>Titelbild </v-card-title>
      <v-img
        v-if="titleImg"
        :src="titleImg.url"
        alt="Titel bild"
        max-height="400"
        contain
      />
      <p v-else>Noch kein Titelbild vorhanden</p>

      <v-card-actions>
        <v-btn v-if="titleImg" @click="uploadNewImg('title', titleImg.key)">
          <v-icon>mdi-file-edit</v-icon>
          Bild ändern
        </v-btn>
        <v-btn v-else @click="uploadNewImg('title')">
          <v-icon>mdi-file-edit</v-icon>
          Neues bild
        </v-btn>
      </v-card-actions>
    </v-card>

    <v-card>
      <v-card-title>Märkte Hintergrund </v-card-title>
      <v-img
        v-if="marketImg"
        :src="marketImg.url"
        alt="Märkte bild"
        max-height="400"
        contain
      />
      <p v-else>Noch kein Markt Hintergrund vorhanden</p>

      <v-card-actions>
        <v-btn v-if="marketImg" @click="uploadNewImg('market', marketImg.key)">
          <v-icon>mdi-file-edit</v-icon>
          Bild ändern
        </v-btn>
        <v-btn v-else @click="uploadNewImg('market')">
          <v-icon>mdi-file-edit</v-icon>
          Neues bild
        </v-btn>
      </v-card-actions>
    </v-card>

    <!-- image upload dialog -->
    <v-dialog v-model="uploadDialog" persistent max-width="600px">
      <admin-image-upload
        :slug="uploadSlug"
        :imageID="imageToEdit"
        @done="uploadDialog = false"
      />
    </v-dialog>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import AdminImageUpload from "../../adminComponents/AdminImageUpload.vue";
import AdminReorderImages from "../../adminComponents/AdminReorderImages.vue";
export default {
  components: {
    AdminImageUpload,
    AdminReorderImages
  },
  name: "AdminImages",

  data() {
    return {
      //uploader specific variables:
      uploadSlug: null,
      imageToEdit: null,
      uploadDialog: false
    };
  },
  computed: {
    ...mapGetters(["getSingleImage", "getImages"]),
    titleImg() {
      return this.getImages("title")[0];
    },
    marketImg() {
      return this.getImages("market")[0];
    }
  },
  methods: {
    uploadNewImg(slug, imageKey = null) {
      this.uploadSlug = slug;
      if (imageKey) {
        this.imageToEdit = imageKey;
      }
      this.uploadDialog = true;
    }
  }
};
</script>
