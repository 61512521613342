<template>
  <v-container>
    <v-row>
      <v-col>
        <!-- image uploader -->

        <v-card fill-height class="pa-10">
          <image-uploader
            :debug="1"
            :maxWidth="2000"
            :maxHeight="2000"
            :quality="1"
            :autoRotate="true"
            outputFormat="verbose"
            :preview="true"
            :className="['fileinput', { 'fileinput--loaded': hasImage }]"
            :capture="false"
            accept="video/*,image/*"
            doNotResize="['gif', 'svg', 'png]"
            @input="setImage"
          ></image-uploader>
        </v-card>
      </v-col>

      <v-col>
        <!-- image data -->
        <v-card class="pa-10">
          <v-card-title>
            <span v-if="imageID">Bild bearbeiten</span>
            <span v-else>Neues Bild</span>

            <v-img
              v-if="imageID"
              :src="picture.thumbUrl"
              :alt="picture.description"
              height="200"
              width="200"
              contain
            />
          </v-card-title>

          <v-text-field
            label="Text"
            outlined
            v-model="picture.description"
          ></v-text-field>

          <!-- slug selection only if no slug has been passed in -->
          <v-select
            v-if="!slug"
            v-model="picture.slug"
            label="Kategorie"
            :items="imgSlugs"
            item-text="name"
            item-value="key"
          >
          </v-select>

          <v-card-actions>
            <v-btn @click="saveImage">
              speichern
            </v-btn>

            <v-btn @click="$emit('done')">
              abbrechen
            </v-btn>

            <!-- button to switch from editing to new image -->
            <!-- <v-tooltip 
                            bottom
                            v-if="imageID"
                            >
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                    @click="loadImageToEdit()"
                                    fab
                                    small
                                    color="green"
                                    v-bind="attrs"
                                    v-on="on"
                                >
                                    <v-icon>mdi-plus</v-icon>
                                </v-btn>

                                <span>Neues Bild hochladen</span>

                            </template>
                         </v-tooltip> -->
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import ImageUploader from "vue-image-upload-resize";
//https://github.com/kartoteket/vue-image-upload-resize
import { mapGetters } from "vuex";

export default {
  name: "NewImage",

  components: {
    ImageUploader
  },

  data() {
    return {
      imageHost: "https://",
      picture: {
        description: "",
        slug: "",
        order: 99,
        imageData: "",
        name: ""
      },
      hasImage: false,
      rules: {
        nameEmpty: val => (val || "").length > 0 || "Cannot be empty"
      },
      imgSlugs: [
        {
          name: "Produkt Galerie",
          key: "products"
        },
        {
          name: "Slideshow",
          key: "slideshow"
        },
        {
          name: "Markt Hintergrund",
          key: "market"
        },
        {
          name: "Start Bild",
          key: "title"
        }
      ],
      response: null,
      images: [],

      dialog: false,
      toDelete: null
    };
  },
  computed: {
    ...mapGetters(["getAllImages", "getSingleImage", "getImages"])
  },
  props: {
    imageID: { type: String, default: null },
    slug: { type: String }
  },
  methods: {
    setImage(imgData) {
      //info - object with image info only [name, type, newWidth, newHeight, orgWidth, orgHeight, aspectRatio (as Float), modifiedTimestamp, modifiedDate].
      // verbose - object with 'dataUrl' (image as base64 string), 'info' (image info) and 'exif' data (null if not available).
      console.log("adding image data");
      this.hasImage = true;
      this.picture.imageData = imgData.dataUrl;
      this.picture.name = imgData.info.name;
      this.picture.file = null;
    },
    async saveImage() {
      // console.log("saving...")
      // need to rewrite picData since the overwrite through "loadImage" gave this.picture new fields e.g. "file" which we don't want to submit!
      let picData = {
        description: this.picture.description,
        slug: this.picture.slug,
        order: this.picture.order,
        imageData: this.picture.imageData,
        name: this.picture.name
      };

      var submit = null;
      if (this.imageID) {
        submit = await this.$store.dispatch("editPicture", {
          key: this.imageID,
          picData: picData
        });
      } else {
        console.log("FROM FORM");
        console.log(picData);
        submit = await this.$store.dispatch("newPicture", {
          pictureData: picData
        });
      }
      console.log(submit);
      // rewrite in local copy:
      // assuming it has been removed from loca list during editing!!
      this.images.push(submit);
      this.loadImageToEdit();
      this.$emit("done");
    },
    async loadImages() {
      this.loadImageToEdit();
    },
    loadImageToEdit(loadKey = null) {
      if (loadKey) {
        this.imageID = loadKey;
      }
      if (this.imageID) {
        this.picture = this.getSingleImage(this.imageID);
      } else {
        this.picture = {
          description: "",
          slug: this.slug,
          order: 99,
          imageData: "",
          name: ""
        };
      }
    }
  },
  mounted() {
    this.loadImages();
  },
  watch: {
    imageID: function() {
      this.loadImageToEdit();
    }
  }
};
</script>
